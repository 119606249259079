<template>
<!--面包屑区域-->
  <el-breadcrumb separator="/">
    <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
    <el-breadcrumb-item>服务机构申请</el-breadcrumb-item>
  </el-breadcrumb>
</template>

<script>
export default {
  name: 'orgApplyBreadcrumb'
};
</script>

<style scoped>

</style>
