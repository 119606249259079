<template>
  <div class="orgnize">
    <!--面包屑区域-->
    <org-apply-breadcrumb class="breadcrumb"></org-apply-breadcrumb>
    <!--  步骤区域-->
    <router-view></router-view>
  </div>
</template>

<script>
import orgApplyBreadcrumb from './organizeComp/orgApplyBreadcrumb';
// import orgApplySteps from './organizeComp/orgApplySteps'

export default {
  name: 'organization',
  components: {
    orgApplyBreadcrumb
  }
};
</script>

<style scoped>
.orgnize {
  position: relative;
  background-color: #fff;
  padding: 43px 0px 94px;
}
.breadcrumb {
  margin-bottom: 40px;
}
</style>
